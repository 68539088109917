import buttonLogo from "../../../resources/img/desktop-footer-logo.svg";
import "./footerBanner.scss";

const FooterBanner = () => {
  return (
    <section className="footer-banner">
      <div className="footer-banner__content">
        <p className="footer-banner__heading">Looking for a design partner?</p>
        <a
          href="https://calendly.com/mariia-myroshnichenko/design-intro-meeting"
          target="_blank"
          className="footer-banner-button__link"
        >
          <img
            src={buttonLogo}
            alt="logo"
            className="footer-banner-button__logo"
          />
        </a>
      </div>
    </section>
  );
};

export default FooterBanner;
