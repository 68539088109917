import ReadMore from "../../ui/readMore/ReadMore";

import "./aboutUs.scss";

const AboutUs: React.FC = () => {
  return (
    <section className="about-us" id="about-us">
      <hr className="about-us__separator" />

      <p className="about-us__description">
        We help product teams gain traction and boost performance with
        data-driven UX/UI.
      </p>

      <div className="about-us__process">
        <p className="about-us__process-description">
          With research, testing & optimization, we helped clients:
        </p>
        <ul className="about-us__process-list">
          <li>raise $25M+ in funding</li>
          <li>get 12+ leads in 1 month for a new product</li>
          <li>boost conversion rates from 2.5% to 5.5%</li>
          <li>achieve 3X YoY revenue growth.</li>
        </ul>
      </div>

      <div className="about-us__links-container">
        <p className="about-us__links-intro">
          <span className="about-us__bullet" />
          Get to know us
        </p>

        <nav className="about-us__links">
          <ReadMore variant="dark" url="#agency" text="Meet our team" />
          <ReadMore variant="dark" url="#services" text="What we offer" />
        </nav>
      </div>
    </section>
  );
};

export default AboutUs;
