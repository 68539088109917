import "./diverseFields.scss";

const DiverseFields: React.FC = () => {
  return (
    <section className="diverse-fields" id="diverse-fields">
      <p className="diverse-fields__title">Expertise in</p>

      <div className="diverse-fields__columns">
        <ul className="diverse-fields__list">
          <li>AI</li>
          <li>ML & NLP</li>
          <li>Edtech</li>
          <li>Healthtech</li>
          <li>Greentech</li>
        </ul>
        <ul className="diverse-fields__list">
          <li>Martech</li>
          <li>Entertainment</li>
          <li>Fintech</li>
          <li>HRtech</li>
          <li>Retail</li>
        </ul>
      </div>
    </section>
  );
};

export default DiverseFields;
