import "./featuredCard.scss";

type MediaType = "video" | "image";
type CardSize = "large" | "medium" | "small" | "standard" | "tall";
type DescriptionWidth = "wide" | "narrow";

interface MediaItem {
  type: MediaType;
  src: string;
  alt?: string;
  size?: string;
  scaleValue?: number;
}

export interface FeaturedCardProps {
  id: string;
  media: MediaItem | MediaItem[];
  background?: MediaItem;
  desc: string;
  size: CardSize;
  descWidth: DescriptionWidth;
}

const FeaturedCard: React.FC<FeaturedCardProps> = ({
  id,
  media,
  background,
  desc,
  size,
  descWidth,
}) => {
  const renderMediaItem = (item: MediaItem, isBackground: boolean = false) => {
    const className = isBackground
      ? "featured-card__background"
      : background
      ? "featured-card__foreground"
      : "featured-card__media-content";

    if (item.type === "video") {
      return (
        <video
          key={item.src}
          src={item.src}
          className={className}
          style={
            item.scaleValue ? { transform: `scale(${item.scaleValue})` } : {}
          }
          autoPlay
          loop
          muted
          playsInline
        />
      );
    }

    return (
      <img
        key={item.src}
        src={item.src}
        alt={item.alt || "Featured work"}
        className={className}
        style={
          item.scaleValue ? { transform: `scale(${item.scaleValue})` } : {}
        }
        loading="lazy"
      />
    );
  };

  return (
    <article className="featured-card" id={`card-${id}`}>
      <div className={`featured-card__media featured-card__media--${size}`}>
        {background && renderMediaItem(background, true)}
        <div className="featured-card__media-content--wrapper">
          {Array.isArray(media)
            ? media.map((mediaItem) => renderMediaItem(mediaItem))
            : renderMediaItem(media)}
        </div>
      </div>

      <a
        href="#!"
        className={`featured-card__description featured-card__description--${descWidth}`}
      >
        <p>{desc}</p>
        <span className="featured-card__arrow">→</span>
      </a>
    </article>
  );
};

export default FeaturedCard;
