import "./ourTeam.scss";

const OurTeam: React.FC = () => {
  return (
    <section className="our-team" id="agency">
      <img
        className="our-team__image desktop"
        src="../../resources/img/team-desktop.webp"
        alt="Our team"
        loading="lazy"
      />

      <img
        className="our-team__image mobile"
        src="../../resources/img/team-mobile.webp"
        alt="Our team"
        loading="lazy"
      />

      <hr className="our-team__separator" />

      <div className="our-team__title">
        <p>
          We know what it takes to launch and grow - that’s why we work as an
          extension of your team to design products that fit the market.
        </p>
      </div>

      <div className="our-team__inspiration-words">
        <p>
          Enrichment – Interests – Collective aspirations – Experience – Desires
          – Interaction
        </p>
        <p>
          Aspirations – Future – Objectives – Vision – Creativity – Evolution –
          Missions – Humanity
        </p>
        <p>
          Inner aspirations – Personal growth – Solutions – Society –
          Inspiration – Communication
        </p>
      </div>
    </section>
  );
};

export default OurTeam;
