import TitleBlur from "./titleBlur/TitleBlur";
import FeaturedCard, { FeaturedCardProps } from "./featuredCard/FeaturedCard";
import ReadMore from "../../ui/readMore/ReadMore";
import featuredWorksData from "../../db/featuredData.json";
import "./featuredWorks.scss";

const featuredWorks: FeaturedCardProps[] =
  featuredWorksData as FeaturedCardProps[];

const FeaturedWorks: React.FC = () => {
  return (
    <section className="featured-works" id="works">
      <TitleBlur />

      <div className="featured-works__row">
        {featuredWorks.slice(0, 2).map((item, index) => (
          <FeaturedCard key={index} {...item} />
        ))}
      </div>

      <div className="featured-works__row featured-works__row--center">
        <FeaturedCard key={2} {...featuredWorks[2]} />
      </div>

      <div className="featured-works__row">
        {featuredWorks.slice(3, 5).map((item, index) => (
          <FeaturedCard key={index + 3} {...item} />
        ))}
      </div>

      <div className="featured-works__row featured-works__row--center">
        <FeaturedCard key={5} {...featuredWorks[5]} />
      </div>

      <div className="featured-works__about">
        <p>
          <span className="featured-works__about-bullet" />
          Discover our portfolio
        </p>

        <nav className="featured-works__about-links">
          {/*<ReadMore variant='light' url='#!' text='Show all projects' />*/}
          <span></span>
          <ReadMore
            variant="light"
            target="_blank"
            url="https://calendly.com/mariia-myroshnichenko/design-intro-meeting?month=2024-10"
            text="Let's discuss your project"
          />
        </nav>
      </div>
    </section>
  );
};

export default FeaturedWorks;
